import { queryOptions, useQuery } from '@tanstack/react-query'
import { useCurrentActiveOrderId } from '@/features/shop/state/SelectedActiveOrder/hooks'
import {
  useStoreParams,
  type StoreParamsAsKey,
} from '@/features/shared/utils/dataFetching/storeParams'
import {
  fetchCouponsSavings,
  fetchSearchCouponsById,
  fetchSearchProductsWithBarcodes,
} from '@/features/shop/services/Coupons/fetchers'
import { type QueryOptions } from '@/features/shared/utils/dataFetching/types'
import { useUserId } from '@/features/account/services/User/hooks'

export type SearchCouponsByIdQueryKey = readonly [
  string,
  StoreParamsAsKey & { couponId: string }
]
export const searchCouponsByIdQueryOptions = ({
  couponId,
  storeParams,
}: StoreParamsAsKey & { couponId: string }) =>
  queryOptions({
    queryKey: ['Search Coupons', { storeParams, couponId }] as const,
    queryFn: fetchSearchCouponsById,
  })

export const useQuerySearchCouponsById = (couponId: string) => {
  const storeParams = useStoreParams()

  return useQuery({
    ...searchCouponsByIdQueryOptions({ couponId, storeParams }),
    enabled: Boolean(storeParams.store_location_id && couponId),
  })
}

export const getCouponsSavingsQueryKey = (
  params: StoreParamsAsKey & { currentActiveOrderId?: number; userId: number }
) => [CouponsSavingsQuery, params] as const

export const CouponsSavingsQuery = 'Coupons Savings'
export type CouponsSavingsQueryKey = ReturnType<
  typeof getCouponsSavingsQueryKey
>
export const couponsSavingsQueryOptions = (
  params: Parameters<typeof getCouponsSavingsQueryKey>[0]
) =>
  queryOptions({
    queryKey: getCouponsSavingsQueryKey(params),
    queryFn: fetchCouponsSavings,
  })

export const useQueryCouponsSavings = (options?: QueryOptions) => {
  const storeParams = useStoreParams()
  const { store_location_id } = storeParams
  const currentActiveOrderId = useCurrentActiveOrderId()
  const userId = useUserId() || 0

  return useQuery({
    ...couponsSavingsQueryOptions({
      currentActiveOrderId,
      storeParams,
      userId,
    }),
    enabled: Boolean(store_location_id),
    ...options,
  })
}

type Barcode = { format: 'UPC_A'; value: string }
type SearchProductsWithBarcodesParams = Pick<
  StoreParamsAsKey['storeParams'],
  'store_location_id' | 'store_id' | 'metro_id'
> & { barcodes: Barcode[]; couponId: string }

export type SearchProductsWithBarcodesQueryKey = readonly [
  string,
  SearchProductsWithBarcodesParams
]
export const searchProductsWithBarcodesQueryOptions = (
  params: SearchProductsWithBarcodesParams
) =>
  queryOptions({
    queryKey: ['Search Products With Barcodes', params],
    queryFn: fetchSearchProductsWithBarcodes,
  })

export const useQuerySearchProductsWithBarcodes = (
  couponBarcodes: string[] | undefined,
  couponId = ''
) => {
  const barcodes = (couponBarcodes ?? []).map<Barcode>((barcode) => ({
    format: 'UPC_A',
    value: barcode,
  }))
  const { store_location_id, store_id, metro_id } = useStoreParams()

  return useQuery({
    ...searchProductsWithBarcodesQueryOptions({
      store_location_id,
      store_id,
      metro_id,
      barcodes,
      couponId,
    }),
    enabled: Boolean(couponBarcodes && couponId && store_location_id),
  })
}
